import { Button, Typography } from '@l_ine/core';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { ErrorGrid } from './index.styles';
import type { ErrorProps } from './index.types';

const Error: React.FC<ErrorProps> = ({ statusCode }) => {
  const router = useRouter();
  const errors = {
    500: {
      message: 'Nosso servidor não conseguiu retornar sua solicitação.',
      explanation:
        'Ocorreu um erro em um dos nossos servidores, tente novamente mais tarde.',
      image: '/static/images/500.png',
    },
    404: {
      message: 'Não conseguimos encontrar essa página.',
      explanation:
        'A página que você está procurando pode ter sido movida, removida, renomeada ou nunca ter existido.',
      image: '/static/images/404.png',
    },
  };

  return (
    <>
      <Head>
        <title>Erro {statusCode}</title>
      </Head>
      <ErrorGrid>
        <div className='error-message'>
          <Typography variant='titleXXL'>{statusCode}</Typography>
          <Typography variant='bodyXL' style={{ marginTop: 16 }}>
            {errors[statusCode].message}
          </Typography>
          <Typography variant='bodySM' style={{ margin: '16px 0px' }}>
            {errors[statusCode].explanation}
          </Typography>
          <Button
            data-testid={`${statusCode}--button-voltar`}
            onClick={() => router.back()}
          >
            Ir para página anterior
          </Button>
        </div>
        <div className='error-image'>
          <img src={errors[statusCode].image} />
        </div>
      </ErrorGrid>
    </>
  );
};

export default Error;
